import { useState } from 'react';
import WrapperSection from '../../../../Components/WrapperSection';
import WrapperSectionTitle from '../../../../Components/WrapperSection/title';
import ImpactAndInclusionsCircle, {
    CircleIconType,
} from '../../../../Icons/impacts-and-inclusions/circle-svg';
import Copydeck from '../../../../i18n/Copydeck';
import './index.scss';

const InclusionsAndImpactsCoreValues = () => {
    const [currentIcon, setCurrentIcon] = useState<CircleIconType>('windmill');

    interface BlockPosition {
        top: string;
        left: string;
        backgroundColor: string;
        icon: CircleIconType;
    }

    const blockPositions: BlockPosition[] = [
        { top: '0', left: '0', backgroundColor: 'red', icon: 'windmill' },
        { top: '0', left: '50%', backgroundColor: 'blue', icon: 'head' },
        { top: '50%', left: '0', backgroundColor: 'green', icon: 'earth' },
        { top: '50%', left: '50%', backgroundColor: 'yellow', icon: 'thumb' },
    ];

    const onBlockClick = (icon: CircleIconType) => {
        setCurrentIcon(icon);
    };

    const subtainabilityTexts = () => (
        <>
            <h3
                className="text-body-large"
                dangerouslySetInnerHTML={{
                    __html: Copydeck.impactsAndInclusionsCoreValuesSustainabilityText1,
                }}
            />
            <p className="text-body">
                {Copydeck.impactsAndInclusionsCoreValuesSustainabilityText2}
            </p>
            <p className="text-body">
                {Copydeck.impactsAndInclusionsCoreValuesSustainabilityText3}
            </p>
            <p className="text-body">
                {Copydeck.impactsAndInclusionsCoreValuesSustainabilityText4}
            </p>
        </>
    );

    const innovationTexts = () => (
        <>
            <h3 className="text-body-large">
                {Copydeck.impactsAndInclusionsCoreValuesInnovationText1}
            </h3>
            <p className="text-body">{Copydeck.impactsAndInclusionsCoreValuesInnovationText2}</p>
            <p className="text-body">{Copydeck.impactsAndInclusionsCoreValuesInnovationText3}</p>
        </>
    );

    const inclusionTexts = () => (
        <>
            <h3 className="text-body-large">
                {Copydeck.impactsAndInclusionsCoreValuesInclusionText1}
            </h3>
            <p className="text-body">{Copydeck.impactsAndInclusionsCoreValuesInclusionText2}</p>
            <p className="text-body">{Copydeck.impactsAndInclusionsCoreValuesInclusionText3}</p>
        </>
    );

    const integrityTexts = () => (
        <>
            <h3 className="text-body-large">
                {Copydeck.impactsAndInclusionsCoreValuesIntegrityText1}
            </h3>
            <p className="text-body">{Copydeck.impactsAndInclusionsCoreValuesIntegrityText2}</p>
        </>
    );

    return (
        <WrapperSection paddingInMobile="80" padding="120" className="CoreValues">
            <div className="content">
                <div className="title-container">
                    <WrapperSectionTitle
                        htmlTag="h2"
                        className="title capitalize"
                        dangerousHTML={Copydeck.impactsAndInclusionsCoreValuesTitle}
                    />
                    <p className="paragraph text-body">
                        {Copydeck.impactsAndInclusionsCoreValuesSubtitle}
                    </p>
                </div>
                <div className="CoreValuesCircle">
                    <div className="overlay">
                        <div className="overlay-text">
                            <span
                                className={`windmillText text-body-large-primary-font-bold ${
                                    currentIcon === 'windmill' ? 'active' : 'inactive'
                                }`}
                            >
                                {Copydeck.impactsAndInclusionsCoreValuesSustainabilityTitle}
                            </span>
                            <span
                                className={`headText text-body-large-primary-font-bold ${
                                    currentIcon === 'head' ? 'active' : 'inactive'
                                }`}
                            >
                                {Copydeck.impactsAndInclusionsCoreValuesInnovationTitle}
                            </span>
                            <span
                                className={`earthText text-body-large-primary-font-bold ${
                                    currentIcon === 'earth' ? 'active' : 'inactive'
                                }`}
                            >
                                {Copydeck.impactsAndInclusionsCoreValuesInclusionTitle}
                            </span>
                            <span
                                className={`thumbText text-body-large-primary-font-bold ${
                                    currentIcon === 'thumb' ? 'active' : 'inactive'
                                }`}
                            >
                                {Copydeck.impactsAndInclusionsCoreValuesIntegrityTitle}
                            </span>

                            <div
                                className={`middleText ${
                                    currentIcon === 'windmill' ? 'active' : 'inactive'
                                }`}
                            >
                                {subtainabilityTexts()}
                            </div>
                            <div
                                className={`middleText ${
                                    currentIcon === 'head' ? 'active' : 'inactive'
                                }`}
                            >
                                {innovationTexts()}
                            </div>
                            <div
                                className={`middleText ${
                                    currentIcon === 'earth' ? 'active' : 'inactive'
                                }`}
                            >
                                {inclusionTexts()}
                            </div>
                            <div
                                className={`middleText ${
                                    currentIcon === 'thumb' ? 'active' : 'inactive'
                                }`}
                            >
                                {integrityTexts()}
                            </div>
                        </div>
                        {blockPositions.map((position, index) => (
                            <div
                                className="ClickableBlock"
                                style={position}
                                key={index}
                                onClick={() => onBlockClick(position.icon)}
                                onMouseOver={() => onBlockClick(position.icon)}
                            ></div>
                        ))}
                        <ImpactAndInclusionsCircle activeIcon={currentIcon} />
                    </div>
                </div>
            </div>
        </WrapperSection>
    );
};

export default InclusionsAndImpactsCoreValues;
