import React, { FunctionComponent } from 'react';
import './index.scss';
import { CheckmarkIcon } from '../../Icons/checkmark-icon';

interface CheckboxProps {
    label: string;
    onChange: () => void;
    checked: boolean;
}
const Checkbox: FunctionComponent<CheckboxProps> = ({ label, onChange, checked }) => {
    return (
        <div className="Checkbox cursor-pointer" onClick={onChange}>
            <div className={`box ${checked ? 'checked' : ''}`}>
                {checked && <CheckmarkIcon width={20} height={20} fill="white" />}
            </div>
            <label className="deprecated-text-title-medium-bold cursor-pointer">{label}</label>
        </div>
    );
};

export default Checkbox;
