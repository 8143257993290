import React, { useEffect, useState, useRef } from 'react';
import './index.scss';
import WrapperSection from '../../../../Components/WrapperSection';
import ParallaxImage1 from './../../../../Assets/Img/ImpactsAndInclusions/impacts-1.png';
import ParallaxImage2 from './../../../../Assets/Img/ImpactsAndInclusions/impacts-2.png';
import ParallaxImage3 from './../../../../Assets/Img/ImpactsAndInclusions/impacts-3.png';
import ParallaxImage1Right from './../../../../Assets/Img/ImpactsAndInclusions/impacts-1-right.png';
import ParallaxImage2Right from './../../../../Assets/Img/ImpactsAndInclusions/impacts-2-right.png';
import ParallaxImage3Right from './../../../../Assets/Img/ImpactsAndInclusions/impacts-3-right.png';
import { ArrowBottomIcon } from '../../../../Icons/arrow-bottom';
import { motion, useScroll, useTransform } from 'framer-motion';
import { useWindowDimensions } from '../../../../Hooks/use-window-dimensions';
import Copydeck from '../../../../i18n/Copydeck';

const ParallaxSection = () => {
    const [scrollY, setScrollY] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const [isTitleVisible, setIsTitleVisible] = useState(true);
    const sectionRef = useRef(null);
    const { windowWidth } = useWindowDimensions();

    const fadingTextContainerRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: fadingTextContainerRef,
        offset: ['start end', 'end start'],
    });

    const textOpacity = useTransform(scrollYProgress, [0, 0.5, 1], [0, 1, 0]);

    const scaleImage = (initialValue: number) => {
        if (windowWidth <= 700) {
            return initialValue * 0.4;
        }
        if (windowWidth <= 1170) {
            return initialValue * 0.5;
        }
        if (windowWidth <= 1170) {
            return initialValue * 0.7;
        }

        if (windowWidth <= 1400) {
            return initialValue * 0.8;
        }
        return initialValue * 1;
    };

    const widthDefaultImage1 = scaleImage(389);
    const widthDefaultImage2 = scaleImage(364);
    const widthDefaultImage3 = scaleImage(360);

    const widthDefaultImage1Right = scaleImage(317);
    const widthDefaultImage2Right = scaleImage(345);
    const widthDefaultImage3Right = scaleImage(461);

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
            },
            { threshold: 0.1 } // Ajustez le seuil selon vos besoins
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (scrollY >= 800) {
            setIsTitleVisible(false);
        } else {
            setIsTitleVisible(true);
        }
    }, [scrollY]);

    const getOffsetWithSpeed = (speed: any) => {
        if (!isVisible) return 0;

        const sectionHeight = 766;
        const offset = (scrollY / sectionHeight) * 100 * speed;
        return Math.min(offset, sectionHeight - 100);
    };

    return (
        <motion.div
            className="ParallaxSection"
            ref={sectionRef}
            initial={{ opacity: 0 }}
            animate={{ opacity: isVisible ? 1 : 0 }}
            transition={{ duration: 0.5 }}
        >
            <div className="content">
                <div className="left">
                    <img
                        className="img-1-left"
                        src={ParallaxImage1}
                        width={widthDefaultImage1}
                        alt="ParallaxImage1"
                        style={{ top: `${0 + getOffsetWithSpeed(0.8)}px` }}
                    />
                    <img
                        className="img-2-left"
                        src={ParallaxImage2}
                        width={widthDefaultImage2}
                        alt="ParallaxImage2"
                        style={{
                            top: `${200 + getOffsetWithSpeed(2.4)}px`,
                        }}
                    />
                    <img
                        className="img-3-left"
                        src={ParallaxImage3}
                        width={widthDefaultImage3}
                        alt="ParallaxImage3"
                        style={{ top: `${450 + getOffsetWithSpeed(1.2)}px` }}
                    />
                </div>

                <div className="center">
                    <div className="fading-container" ref={fadingTextContainerRef}>
                        <motion.div style={{ opacity: textOpacity }}>
                            <WrapperSection.Title>
                                <div className="title-background">
                                    {Copydeck.impactsAndInclusionsImpactInclusionParallax}
                                </div>
                            </WrapperSection.Title>
                        </motion.div>
                    </div>

                    {!isTitleVisible && (
                        <motion.div
                            className="bottom-container-scroll"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.5, delay: 0.5 }}
                        >
                            <div className="text-white-alt">
                                {Copydeck.impactsAndInclusionsScrollToLearnMore}
                            </div>
                            <ArrowBottomIcon fill="#BFBFBF" />
                        </motion.div>
                    )}
                </div>
                <div className="right">
                    <img
                        className="img-1-right"
                        src={ParallaxImage1Right}
                        width={widthDefaultImage1Right}
                        alt="ParallaxImage1Right"
                        style={{ top: `${0 + getOffsetWithSpeed(1.4)}px` }}
                    />
                    <img
                        className="img-2-right"
                        src={ParallaxImage2Right}
                        width={widthDefaultImage2Right}
                        alt="ParallaxImage2Right"
                        style={{
                            top: `${100 + getOffsetWithSpeed(2.4)}px`,
                        }}
                    />
                    <img
                        className="img-3-right"
                        src={ParallaxImage3Right}
                        width={widthDefaultImage3Right}
                        alt="ParallaxImage3Right"
                        style={{ top: `${450 + getOffsetWithSpeed(1.3)}px` }}
                    />
                </div>
            </div>
        </motion.div>
    );
};

export default ParallaxSection;
