import { action, makeAutoObservable, observable } from 'mobx';

class OnBoardingModalStore {
    constructor() {
        makeAutoObservable(this);
    }

    @observable
    public isVisible: boolean = false;

    @action
    setIsVisible(visible: boolean): void {
        this.isVisible = visible;
    }
}

export default OnBoardingModalStore;
