import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const ArrowBottomIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" {...props} fill="none">
            <g clip-path="url(#clip0_7496_2203)">
                <path
                    d="M16 5V27"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7 18L16 27L25 18"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_7496_2203">
                    <rect width="32" height="32" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
