import React, { useEffect, useState } from 'react';
import './layout-header.scss';
import { LogoBrand } from '../logo-brand';
import { MenuBurgerIcon } from '../../../Icons/menu-burger-icon';
import { useWindowDimensions } from '../../../Hooks/use-window-dimensions';
import LayoutHeaderMobile from './layout-header-mobile';
import { useTranslation } from 'react-i18next';
import { LockIcon } from '../../../Icons/lock-icon';
import CONSTANTS from '../../../Constants';
import { NAV_OPTIONS } from './config';
import PartnerIcon from '../../../Icons/partner-icon';
import { theme } from '../../../Style/theme';
import { useStores } from '../../../Hooks/use-stores';
import { observer } from 'mobx-react';
import ButtonPartnerAccessDashboard from './Components/button-partner-access-dashboard';
import HeaderSelectLanguage from './Components/select-language';
import useCustomNavigation from '../../../Hooks/use-custom-navigation';
import Copydeck from '../../../i18n/Copydeck';
import { getKeyByValueRecursive } from '../../../Utils/object';

const LayoutHeader = observer(() => {
    const [mobileMenuBurgerIsOpen, setMobileMenuBurgerIsOpen] = useState(false);
    const { windowWidth } = useWindowDimensions();
    const { t } = useTranslation();
    const { welcomeModalStore, userStore, languageStore } = useStores();
    const nav = useCustomNavigation();

    const widthLogoBrand = windowWidth < 1300 ? 140 : windowWidth < 160 ? 180 : 192;

    useEffect(() => {
        if (mobileMenuBurgerIsOpen || welcomeModalStore.isVisible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [mobileMenuBurgerIsOpen, welcomeModalStore.isVisible]);

    return (
        <div className="LayoutHeader">
            <nav className="layout-header-content">
                <div className="logo-container" onClick={() => nav(CONSTANTS.SCREEN.HOME)}>
                    <LogoBrand width={widthLogoBrand} />
                </div>

                <ul className="nav-items">
                    {NAV_OPTIONS.map((navOption, index) => {
                        const pathOfRoutes = (CONSTANTS.ROUTES as any)?.[
                            languageStore.currentLanguage as any
                        ]?.[navOption?.href];
                        const path = window.location.pathname;
                        const keyRoutesOfPath = getKeyByValueRecursive(CONSTANTS.ROUTES, path);
                        let currentPath = '';

                        if (keyRoutesOfPath) {
                            const lastKey: any = keyRoutesOfPath.split('.').pop();
                            if (lastKey)
                                currentPath = (CONSTANTS.ROUTES as any)?.[
                                    (languageStore as any).currentLanguage
                                ]?.[lastKey];
                        }

                        const isNotClickable = navOption?.href === '#';
                        const isCurrentSelected = currentPath === pathOfRoutes;

                        return (
                            <li className="nav-item" key={index}>
                                {isNotClickable ? (
                                    <h1
                                        className={`nav-item-title text-body-medium-primary-font ${
                                            isCurrentSelected ? 'nav-item-selected' : ''
                                        }`}
                                    >
                                        {t(navOption.title)}
                                    </h1>
                                ) : navOption.type === 'external' ? (
                                    <a
                                        href={navOption.href}
                                        className={`nav-item-title text-body-medium-primary-font ${
                                            isCurrentSelected ? 'nav-item-selected' : ''
                                        }`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {t(navOption.title)}
                                    </a>
                                ) : (
                                    <div
                                        onClick={() => {
                                            nav(navOption.href);
                                        }}
                                        className={`nav-item-title text-body-medium-primary-font ${
                                            isCurrentSelected ? 'nav-item-selected' : ''
                                        }`}
                                        rel="noopener noreferrer"
                                    >
                                        {t(navOption.title)}
                                    </div>
                                )}

                                {navOption?.content && (
                                    <div className="nav-item-dropdown">
                                        {navOption?.content?.map((content, index) => {
                                            return (
                                                <div
                                                    className={`nav-subitem subitem-${
                                                        content.type
                                                    } text-body-medium-primary-font ${
                                                        content.href === currentPath
                                                            ? 'nav-item-selected'
                                                            : ''
                                                    }`}
                                                    key={index}
                                                    onClick={() => {
                                                        nav(content.href);
                                                    }}
                                                >
                                                    {t(content.title)}{' '}
                                                    {content.type === 'disabled'
                                                        ? `${'(' + t('coming_soon') + ')'}`
                                                        : null}
                                                    {content.type === 'locked' ? (
                                                        <div className="nav-subitem-locked">
                                                            <LockIcon />
                                                        </div>
                                                    ) : null}
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </li>
                        );
                    })}
                </ul>

                <div className="actions">
                    <ButtonPartnerAccessDashboard
                        label={
                            userStore.isLogin()
                                ? t('HeaderNav.partner_access')
                                : t('HeaderNav.partner_access_no_login')
                        }
                        icon={<PartnerIcon fill={theme['surface-brand-rest-tertiary']} />}
                    />

                    {userStore.isLogin() ? null : (
                        <div
                            onClick={() => {
                                nav(CONSTANTS.SCREEN.LOGIN);
                            }}
                            className="text-body-medium-primary-font-bold cta-login"
                        >
                            {Copydeck.loginLoginSpace}
                        </div>
                    )}

                    <HeaderSelectLanguage />
                </div>

                <div
                    className="content-menu-burger"
                    onClick={() => setMobileMenuBurgerIsOpen(true)}
                >
                    <MenuBurgerIcon />
                </div>

                {mobileMenuBurgerIsOpen ? (
                    <LayoutHeaderMobile
                        onClose={() => {
                            setMobileMenuBurgerIsOpen(false);
                        }}
                    />
                ) : null}
            </nav>
        </div>
    );
});

export default LayoutHeader;
