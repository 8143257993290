import { useCallback, useEffect, useState } from 'react';
import TabElement from '../../../../Components/TabElement';
import './index.scss';
import UseServices from '../../../../Hooks/use-services';
import { CategoryDto } from '../../../../Services/Blog';
import { theme } from '../../../../Style/theme';

interface CategoryFilterProps {
    onChangeFilter: (filter: { value: string; label: string }) => void;
}

const CategoryFilter: React.FC<CategoryFilterProps> = ({ onChangeFilter }) => {
    const [topElementClassName, setTopElementClassName] = useState<string>('CategoryFilter');
    const [categories, setCategories] = useState<CategoryDto[]>([]);
    const { blogService } = UseServices();

    const [currentFilter, setCurrentFilter] = useState({
        value: 'all',
        label: 'all',
    });

    useEffect(() => {
        if (!currentFilter) {
            return;
        }

        onChangeFilter(currentFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFilter]);

    useEffect(() => {
        const getTopElementClassName = (event: UIEvent) => {
            const element = document.querySelector('.CategoryFilter');
            const windowWidth = window.innerWidth;

            if (!element) {
                return setTopElementClassName('CategoryFilter');
            }

            if (element.scrollWidth + 32 > windowWidth) {
                return setTopElementClassName('CategoryFilter CategoryFilter--scrollable');
            }
            return setTopElementClassName('CategoryFilter');
        };

        window.addEventListener('resize', getTopElementClassName);

        return () => {
            window.removeEventListener('resize', getTopElementClassName);
        };
    }, []);

    const getAllCategories = useCallback(async () => {
        try {
            const response = await blogService.getAllCategories();

            setCategories(
                response.data?.filter(
                    (category) => category?.attributes?.articles?.data?.length > 0
                )
            );
        } catch (error) {
            console.error(error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getAllCategories();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (categories.length === 0) {
        return null;
    }

    return (
        <div className={topElementClassName}>
            <TabElement
                isActive={currentFilter.value === 'all'}
                label="All"
                value="id"
                onClick={() =>
                    setCurrentFilter({
                        value: 'all',
                        label: 'all',
                    })
                }
                key="id"
                backgroundColor="#EBFAF3"
                textColor="#2EB67D"
            />

            {categories.map(({ attributes, id }) => {
                return (
                    <TabElement
                        isActive={currentFilter.value === id}
                        label={attributes?.name}
                        value={id}
                        onClick={() =>
                            setCurrentFilter({
                                value: id,
                                label: attributes.name,
                            })
                        }
                        key={id}
                        backgroundColor={attributes.backgroundColor || theme['white']}
                        textColor={attributes.primaryColor || theme['white']}
                    />
                );
            })}
        </div>
    );
};

export default CategoryFilter;
