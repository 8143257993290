import './index.scss';

import CONSTANTS from '../../../../Constants';
import useCustomNavigation from '../../../../Hooks/use-custom-navigation';
import WrapperSection from '../../../../Components/WrapperSection';
import Button from '../../../../Components/Button';
import { useTranslation } from 'react-i18next';
import { useRulesOfThrees } from '../../../../Hooks/use-rules-of-threes';

const Hero = () => {
    const nav = useCustomNavigation();
    const { t } = useTranslation();

    const test = useRulesOfThrees(1100);

    const onClickProductOverview = () => nav(CONSTANTS.SCREEN.WHAT_IS_SPREEAI);

    return (
        <WrapperSection className="Hero">
            <div
                className="wrapper-video"
                style={{
                    height: test,
                }}
            >
                <video
                    autoPlay
                    muted
                    loop
                    playsInline
                    style={{
                        height: test,
                    }}
                >
                    <source
                        src={require('../../../../Assets/MP4/view-demo-complete.webm')}
                        type="video/webm"
                    />
                    <source
                        src={require('../../../../Assets/MP4/view-demo-complete.mp4')}
                        type="video/mp4"
                    />
                </video>
            </div>

            <div className="inner">
                <div className="extra-info">
                    <WrapperSection.Title htmlTag="h1" className="text-white">
                        {t('HomePage.home_page_title')}
                    </WrapperSection.Title>

                    <div className="text-body text-white">{t('HomePage.home_page_subtitle')}</div>
                </div>

                <Button
                    type="white"
                    text={t('HomePage.cta_title_primary')}
                    onClick={onClickProductOverview}
                />
            </div>
        </WrapperSection>
    );
};

export default Hero;
