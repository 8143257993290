import './index.scss';
import Copydeck from '../../../../i18n/Copydeck';
import WrapperSection from '../../../../Components/WrapperSection';
import { LaptopIcon } from '../../../../Icons/laptop-icon';
import { HeartBeatIcon } from '../../../../Icons/heart-beat-icon';
import { HandCoinsIcon } from '../../../../Icons/hand-coins-icon';
import { PiggyBankIcon } from '../../../../Icons/piggy-bank-icon';
import { GlobeHemisphereWestIcon } from '../../../../Icons/globe-gemisphere-west-icon';
import { UsersThreeIcon } from '../../../../Icons/users-three-icon';

const LifeAtSpreeAIPageSectionBenefits = () => {
    const BENEFITS = [
        {
            icon: HeartBeatIcon,
            title: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit1Title,
            paragraph: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit1Description,
            backgroundColor: '#F9F9F9',
            color: '#2b2b2b',
        },
        {
            icon: HandCoinsIcon,
            title: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit2Title,
            paragraph: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit2Description,
            backgroundColor: '#F9F9F9',
            color: '#2b2b2b',
        },
        {
            icon: PiggyBankIcon,
            title: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit3Title,
            paragraph: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit3Description,
            backgroundColor: '#F9F9F9',
            color: '#2b2b2b',
        },
        {
            icon: LaptopIcon,
            title: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit4Title,
            paragraph: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit4Description,
            backgroundColor: '#F9F9F9',
            color: '#2b2b2b',
        },
        {
            icon: GlobeHemisphereWestIcon,
            title: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit5Title,
            paragraph: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit5Description,
            backgroundColor: '#F9F9F9',
            color: '#2b2b2b',
        },
        {
            icon: UsersThreeIcon,
            title: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit6Title,
            paragraph: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit6Description,
            backgroundColor: '#F9F9F9',
            color: '#2b2b2b',
        },
    ];

    return (
        <div className="LifeAtSpreeAIPageSectionBenefits">
            <div className="content">
                <WrapperSection.Title className="title capitalize" htmlTag="h1">
                    {Copydeck.lifeAtSpreeAipageBenefitsSectionOurBenefits}
                </WrapperSection.Title>

                <div className="text-body sub-paragraph">
                    {Copydeck.lifeAtSpreeAipageBenefitsSectionOurBenefitsSubtitle}
                </div>

                <ul className="benefits">
                    {BENEFITS.map((benefit, index) => {
                        const IconComponent = benefit.icon;

                        return (
                            <li key={index} className="benefit">
                                <div
                                    className="benefit-wrapper-icon"
                                    style={{
                                        backgroundColor: benefit.backgroundColor,
                                    }}
                                >
                                    <IconComponent fill={benefit.color} width={40} height={40} />
                                </div>

                                <div className="benefit-info">
                                    <div className="benefit-title text-body-primary-font-bold text-element-black-secondary-alt">
                                        {benefit.title}
                                    </div>
                                    <div className="benefit-description text-body">
                                        {benefit.paragraph}
                                    </div>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default LifeAtSpreeAIPageSectionBenefits;
