import React, { useEffect } from 'react';
import './index.scss';
import CardSustainabillity from './components/card-sustainabillity/card';
import { ChevronLeftIcon } from '../../../../Icons/chevron-left-icon';
import { ChevronRightIcon } from '../../../../Icons/chevron-right-icon';
import { theme } from '../../../../Style/theme';
import { useWindowDimensions } from '../../../../Hooks/use-window-dimensions';
import { HandHeartIcon } from '../../../../Icons/hand-heart-icon';
import { TreeIcon } from '../../../../Icons/tree-icon';
import { UserSubstaIcon } from '../../../../Icons/user-substa-icon';
import { ScaleIcon } from '../../../../Icons/scale-icon';
import { PackageIcon } from '../../../../Icons/package-icon';
import { ThreeSubIcon } from '../../../../Icons/three-sub-icon';
import { useTranslation } from 'react-i18next';

const SustainabilitySection = () => {
    const [currentStepIndex, setCurrentStepIndex] = React.useState(0);
    const { windowWidth } = useWindowDimensions();
    const { t } = useTranslation();

    const isMobile = windowWidth <= 1000;

    const marginLeftArray = isMobile
        ? [16, -250, -500, -800, -1050, -1350, -1300]
        : [320, 0, -320, -640, -960, -1280];

    const [currentCardFlip, setCurrentCardFlip] = React.useState(-0.5);
    const [disabledCardFlip, setDisabledCardFlip] = React.useState(false);

    const TIMOUT_CARD_FLIP = isMobile ? 3800 : 3800;

    useEffect(() => {
        if (disabledCardFlip) return;
        const interval = setInterval(() => {
            if (currentCardFlip === marginLeftArray.length) {
                setCurrentCardFlip(0);
                return;
            }

            setCurrentCardFlip((prev) => prev + (isMobile ? 0.5 : 0.5));
        }, TIMOUT_CARD_FLIP);
        return () => clearInterval(interval);
    }, [disabledCardFlip]);

    const onPressArrow = (isManuel: boolean, direction: 'left' | 'right') => {
        if (isManuel) {
            setDisabledCardFlip(true);
            setTimeout(() => {
                setDisabledCardFlip(false);
            }, 8000);
        }
        if (direction === 'left' && currentStepIndex <= 0) {
            return;
        }

        if (direction === 'right' && currentStepIndex >= 8) {
            return;
        }

        setCurrentStepIndex((prev) => {
            if (direction === 'right') {
                return prev + 1;
            }

            return prev - 1;
        });
    };

    const onMouseEnter = () => {
        setDisabledCardFlip(true);
    };

    const onMouseLeave = () => {
        setDisabledCardFlip(false);
    };

    const onManualFlip = () => {
        setDisabledCardFlip(true);
    };

    useEffect(() => {
        if (currentCardFlip >= 0) {
            if (currentCardFlip === marginLeftArray.length) {
                setCurrentCardFlip(0);
                setCurrentStepIndex(-1);
                return;
            }

            if (currentCardFlip < 1) {
                return;
            }

            onPressArrow(false, 'right');
        }
    }, [currentCardFlip]);

    return (
        <div className="SustainabilitySection">
            <div
                className="cards"
                style={{
                    marginLeft: `${marginLeftArray[currentStepIndex]}px`,
                }}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <CardSustainabillity
                    item={{
                        icon: () => <HandHeartIcon width={50} height={50} fill={theme['white']} />,
                        title: t('CardsSustainability.title_card_1_sustainabillity'),
                        description: t('CardsSustainability.desc_card_1_sustainabillity'),
                        longDescription: t('CardsSustainability.long_desc_card_1_sustainabillity'),
                        backgroundImage: require('./../../../../Assets/Img/ImpactsAndInclusions/cards/card-1.png'),
                    }}
                    flipUpdate={currentCardFlip === 0}
                    isFlipControlled
                    onManualFlip={onManualFlip}
                />
                <CardSustainabillity
                    item={{
                        icon: () => <TreeIcon width={50} height={50} fill={theme['white']} />,
                        title: t('CardsSustainability.title_card_2_sustainabillity'),
                        description: t('CardsSustainability.desc_card_2_sustainabillity'),
                        longDescription: t('CardsSustainability.long_desc_card_2_sustainabillity'),
                        backgroundImage: require('./../../../../Assets/Img/ImpactsAndInclusions/cards/card-2.png'),
                    }}
                    flipUpdate={currentCardFlip === 1}
                    isFlipControlled
                    onManualFlip={onManualFlip}
                />
                <CardSustainabillity
                    item={{
                        icon: () => <UserSubstaIcon width={50} height={50} fill={theme['white']} />,
                        title: t('CardsSustainability.title_card_3_sustainabillity'),
                        description: t('CardsSustainability.desc_card_3_sustainabillity'),
                        longDescription: t('CardsSustainability.long_desc_card_3_sustainabillity'),
                        backgroundImage: require('./../../../../Assets/Img/ImpactsAndInclusions/cards/card-6.png'),
                    }}
                    flipUpdate={currentCardFlip === 2}
                    isFlipControlled
                    onManualFlip={onManualFlip}
                />
                <CardSustainabillity
                    item={{
                        icon: () => <ThreeSubIcon width={50} height={50} fill={theme['white']} />,
                        title: t('CardsSustainability.title_card_4_sustainabillity'),
                        description: t('CardsSustainability.desc_card_4_sustainabillity'),
                        longDescription: t('CardsSustainability.long_desc_card_4_sustainabillity'),
                        backgroundImage: require('./../../../../Assets/Img/ImpactsAndInclusions/cards/card-3.png'),
                    }}
                    flipUpdate={currentCardFlip === 3}
                    isFlipControlled
                    onManualFlip={onManualFlip}
                />
                <CardSustainabillity
                    item={{
                        icon: () => <PackageIcon width={50} height={50} fill={theme['white']} />,
                        title: t('CardsSustainability.title_card_5_sustainabillity'),
                        description: t('CardsSustainability.desc_card_5_sustainabillity'),
                        longDescription: t('CardsSustainability.long_desc_card_5_sustainabillity'),
                        backgroundImage: require('./../../../../Assets/Img/ImpactsAndInclusions/cards/card-4.png'),
                    }}
                    flipUpdate={currentCardFlip === 4}
                    isFlipControlled
                    onManualFlip={onManualFlip}
                />
                <CardSustainabillity
                    item={{
                        icon: () => <ScaleIcon width={50} height={50} fill={theme['white']} />,
                        title: t('CardsSustainability.title_card_6_sustainabillity'),
                        description: t('CardsSustainability.desc_card_6_sustainabillity'),
                        longDescription: t('CardsSustainability.long_desc_card_6_sustainabillity'),
                        backgroundImage: require('./../../../../Assets/Img/ImpactsAndInclusions/cards/card-5.png'),
                    }}
                    flipUpdate={currentCardFlip === 5}
                    isFlipControlled
                    onManualFlip={onManualFlip}
                />
            </div>

            <div className="cta-container">
                <div
                    onClick={() => {
                        onPressArrow(true, 'left');
                    }}
                    className={`btn-arrow -left ${currentStepIndex <= 0 ? '-disabled' : ''}`}
                >
                    <ChevronLeftIcon height={24} width={24} fill={theme['white']} />
                </div>

                <div
                    onClick={() => {
                        onPressArrow(true, 'right');
                    }}
                    className={`btn-arrow -right ${
                        currentStepIndex >= marginLeftArray.length ? '-disabled' : ''
                    }`}
                >
                    <ChevronRightIcon height={24} width={24} fill={theme['white']} />
                </div>
            </div>
        </div>
    );
};

export default SustainabilitySection;
