import Copydeck from '../../../../i18n/Copydeck';
import './index.scss';
import WrapperSection from '../../../../Components/WrapperSection';
import UseServices from '../../../../Hooks/use-services';
import { useCallback, useEffect, useState } from 'react';
import { ArticleDto } from '../../../../Services/Blog';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import CONSTANTS from '../../../../Constants';
import WrapperImage from '../../../../Components/WrapperImage';
import useCustomNavigation from '../../../../Hooks/use-custom-navigation';
import { theme } from '../../../../Style/theme';
import { Spin } from 'antd';
import { formatDate } from '../../../../Utils/object';
import { useStores } from '../../../../Hooks/use-stores';
import { SupportedLanguage } from '../../../../Stores/LanguageStore';

const BlogPageBanner = () => {
    const { blogService } = UseServices();
    const { languageStore } = useStores();
    const [lastArticle, setLastArticle] = useState<ArticleDto | null>(null);
    const { t } = useTranslation();
    const nav = useCustomNavigation();

    const getTheLastestArticles = useCallback(async () => {
        try {
            const response = await blogService.getMostRecentArticles(1);

            if (!response?.data?.[0]) {
                toast.error(t('BlogPage.fetch_error') as string);
            }
            setLastArticle(response?.data?.[0]);
        } catch (error) {
            console.error(error);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getTheLastestArticles();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClickArticle = () => {
        if (lastArticle) {
            nav(CONSTANTS.SCREEN.BLOG_ID, `${lastArticle.id}-${lastArticle.attributes.slug}`);
        }
    };

    return (
        <div className="BlogPageBanner">
            <div className="content">
                <WrapperSection.Title className="title deprecated-text-display-large" htmlTag="h1">
                    <span
                        dangerouslySetInnerHTML={{
                            __html: Copydeck.blogPageBannerTitle,
                        }}
                    />
                </WrapperSection.Title>

                <div className="card" onClick={onClickArticle}>
                    <div
                        className="left-side"
                        style={{
                            backgroundColor:
                                lastArticle?.attributes?.category?.data?.attributes
                                    ?.backgroundColor || theme['white'],
                        }}
                    >
                        {lastArticle?.attributes?.cover?.data?.attributes?.formats?.medium?.url ||
                        lastArticle?.attributes?.cover?.data?.attributes?.url ? (
                            <WrapperImage
                                uniqueSrc={
                                    lastArticle?.attributes?.cover?.data?.attributes?.formats
                                        ?.medium?.url ||
                                    lastArticle?.attributes?.cover?.data?.attributes?.url
                                }
                                alt={Copydeck.blogPageBannerImg1Alt}
                                className="left-side-image"
                            />
                        ) : (
                            <div className="spiner-wrapper">
                                <Spin size="large" />
                            </div>
                        )}
                    </div>

                    <div className="right-side">
                        <p className="category text-body">
                            {lastArticle?.attributes?.category?.data?.attributes.name}
                        </p>
                        <WrapperSection.Title className="welcome">
                            {lastArticle?.attributes?.title}
                        </WrapperSection.Title>

                        <div className="category-extra-info">
                            <p className="text-element-greys-secondary text-body">
                                {formatDate(
                                    lastArticle?.attributes?.publishedAt || '',
                                    languageStore.currentLanguage || SupportedLanguage.EN
                                )}
                            </p>
                            <p className="text-element-greys-secondary text-body">
                                <span>{Copydeck.blogPageBannerBy}</span>
                                <span>
                                    {' '}
                                    {lastArticle?.attributes?.author?.data?.attributes?.name}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogPageBanner;
