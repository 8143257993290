import { useTranslation } from 'react-i18next';
import Button from '../../../../Components/Button';
import WrapperSection from '../../../../Components/WrapperSection';
import { useStores } from '../../../../Hooks/use-stores';
import './index.scss';
import { observer } from 'mobx-react';
import WrapperVideo from '../../../../Components/WrapperVideo';

const AnnouncementSection = observer(() => {
  const { onboardingModalStore } = useStores();
  const { t } = useTranslation();

  const onClick = () => {
    onboardingModalStore.setIsVisible(true);
  };

  return (
    <div className="AnnouncementSection">
      <WrapperVideo
        alt='Announcement Trailer'
        classNameOnVideo='TrailerVideo'
        className='TrailerVideoContainer'
        muted={true}
        src={{
          en: {
            desktop: {
              mp4: require('../../../../Assets/MP4/annoucement/announcement-trailer.mp4'),
              webm: require('../../../../Assets/MP4/annoucement/announcement-trailer.webm')
            },
            mobile: {
              mp4: require('../../../../Assets/MP4/annoucement/announcement-trailer.mp4'),
              webm: require('../../../../Assets/MP4/annoucement/announcement-trailer.webm')
            }
          }
        }} />

      <div className="Overlay">
        <WrapperSection.Title className="text-white">
          {t('HomePage.annoucement_title')}
        </WrapperSection.Title>
        <Button
          onClick={onClick}
          text={t('HomePage.annoucement_cta')}
          type="white"
          fullInMobile={false}
        />
      </div>
    </div>
  );
});

export default AnnouncementSection;
