import AccountService from '../Services/Account';
import BlogService from '../Services/Blog';
import CareerService from '../Services/Careers';
import ContactUsService from '../Services/ContactUs';
import SupportRequestService from '../Services/SupportRequest';

const UseServices = () => {
    const contactUsService = new ContactUsService();
    const supportRequestService = new SupportRequestService();
    const accountService = new AccountService();
    const careerService = new CareerService();
    const blogService = new BlogService();

    return {
        contactUsService,
        supportRequestService,
        accountService,
        careerService,
        blogService,
    };
};

export default UseServices;
