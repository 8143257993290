import React, { useMemo } from 'react';
import { LogoBrand } from '../logo-brand';
import CONSTANTS from '../../../Constants';
import { useWindowDimensions } from '../../../Hooks/use-window-dimensions';
import './create-account-header.scss';
import { useTranslation } from 'react-i18next';
import useCustomNavigation from '../../../Hooks/use-custom-navigation';

const CreateAccountHeader = () => {
    const { windowWidth } = useWindowDimensions();
    const { t } = useTranslation();
    const nav = useCustomNavigation();

    const widthLogoBrand = useMemo(() => (windowWidth < 1130 ? 140 : 192), [windowWidth]);

    return (
        <div className="CreateAccountHeader">
            <div
                className="home deprecated-text-body-large"
                onClick={() => nav(CONSTANTS.SCREEN.HOME)}
            >
                {t('CreateAccount.home')}
            </div>

            <div
                className="logo-container cursor-pointer"
                onClick={() => nav(CONSTANTS.SCREEN.HOME)}
            >
                <LogoBrand width={widthLogoBrand} />
            </div>
        </div>
    );
};

export default CreateAccountHeader;
