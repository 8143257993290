import React from 'react';
import AboutUsSectionHero from './Sections/Hero';
import AboutUsSectionLeaders from './Sections/Leaders';
import AboutUsSectionRevolution from './Sections/Revolution';
import AboutUsTeamsSectionPage from './Sections/Teams';
import AboutUsCoresValueSectionPage from './Sections/CoresValues';
import './index.scss';

const AboutUsPage = () => {
    return (
        <div className="AboutUsPage">
            <AboutUsSectionHero />
            <AboutUsSectionLeaders />
            <AboutUsTeamsSectionPage />
            <AboutUsCoresValueSectionPage />
            <AboutUsSectionRevolution />
        </div>
    );
};

export default AboutUsPage;
