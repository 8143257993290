import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../Hooks/use-stores';
import LoginIcon from '../../Icons/login-icon';
import './index.scss';
import CardDashboard, { CardDashboardProps } from './Components/card-dashboard';
import Button from '../../Components/Button';
import CONSTANTS from '../../Constants';
import { LeftArrowIcon } from '../../Icons/left-arrow-icon';
import Modal from '../../Components/Modal';
import UseServices from '../../Hooks/use-services';
import Form from '../../Components/Form';
import { theme } from '../../Style/theme';
import { observer } from 'mobx-react-lite';
import useCustomNavigation from '../../Hooks/use-custom-navigation';
import { FeaturePartnerIcon } from '../../Icons/feature-partner-icon';
import FeatureVideoIcon from '../../Icons/feature-video-icon';
import { FeatureMultimediaIcon } from '../../Icons/feature-multimedia-icon';
import DropdownUser from './Components/dropdown-user';
import WrapperVideo from '../../Components/WrapperVideo';
import WrapperSection from '../../Components/WrapperSection';

const DashboardPage = observer(() => {
    const { t } = useTranslation();
    const { userStore, tokenStore, loadingStore } = useStores();
    const { accountService } = UseServices();
    const nav = useCustomNavigation();
    const [isShowModalDeleteAccount, setIsShowModalDeleteAccount] = useState<boolean>(false);
    const [currentPasswordIsError, setCurrentPasswordIsError] = useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>('');

    const [currentMode, setCurrentMode] = useState<
        'default' | 'manage_account' | 'manage_account_change_password'
    >('default');

    const onSubmitChangePassword = async (values: any): Promise<boolean> => {
        setCurrentPasswordIsError(false);
        setPasswordErrorMessage('');
        if (values?.password !== values?.confirm_password) {
            return false;
        }

        if (!values?.current_password) {
            setCurrentPasswordIsError(true);
            setPasswordErrorMessage(t('Errors.e_required'));
            return false;
        }

        try {
            await accountService.authUpdatePassword({
                current_password: values.current_password,
                new_password: values.password,
            });
        } catch (err: any) {
            setCurrentPasswordIsError(true);
            setPasswordErrorMessage(t('ManageAccount.input_current_password_error'));

            return false;
        }

        return true;
    };
    useEffect(() => {
        if (isShowModalDeleteAccount) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [isShowModalDeleteAccount]);

    const onClickManageAccount = () => setCurrentMode('manage_account');
    const onClickPartnerRessources = () => nav(CONSTANTS.SCREEN.PARTNER_ASKS);

    const Cards: CardDashboardProps[] = [
        {
            icon: (color: string) => <FeaturePartnerIcon fill={color} width={40} height={40} />,
            title: t('DashboardPage.partner_ressources'),
            description: t('DashboardPage.partner_ressources_desc'),
            onClick: onClickPartnerRessources,

            isCommingSoon: false,
        },
        {
            icon: (color: string) => <FeatureVideoIcon fill={color} width={40} height={40} />,
            title: t('view_demo'),
            description: t('DashboardPage.view_demo_desc'),

            isCommingSoon: true,
        },
        {
            icon: (color: string) => <FeatureMultimediaIcon fill={color} width={40} height={40} />,
            title: t('DashboardPage.manage_assets'),
            description: t('DashboardPage.manage_assets_desc'),

            isCommingSoon: true,
        },
    ];

    const handleDeleteAccount = async () => setIsShowModalDeleteAccount((prev) => !prev);

    const onClickLogout = () => {
        tokenStore.clearTokens();
        userStore.clearUserInfo();
        nav(CONSTANTS.SCREEN.HOME);
    };

    const confirmDeleteAccount = async () => {
        try {
            loadingStore.addLoading();
            await accountService.deleteMe();
            tokenStore.clearTokens();
            userStore.clearUserInfo();
            nav(CONSTANTS.SCREEN.HOME);
        } catch (err: any) {
            setIsShowModalDeleteAccount(false);
        } finally {
            loadingStore.removeLoading();
        }
    };

    const onSubmit = async (values: any): Promise<boolean> => {
        if (!values) {
            return false;
        }

        try {
            loadingStore.addLoading();
            await accountService.updateInfo({
                first_name: values?.first_name || userStore?.userInfo?.first_name,
                last_name: values.last_name,
                company: values.company,
                company_role: values.role,
                email: userStore.userInfo.email || '',
                company_url: values.company_url || undefined,
            });

            userStore.setUserInfo({
                first_name: values?.first_name || userStore?.userInfo?.first_name,
                last_name: values.last_name,
                company: values.company,
                company_role: values.role,
                email: userStore.userInfo.email || '',
                company_url: values.company_url || undefined,
            });
        } finally {
            loadingStore.removeLoading();
        }
        return false;
    };

    const concatCompanyName = `${userStore.userInfo.company}`;

    return (
        <div className="DashboardPage">
            <div className={`top ${currentMode === 'default' ? '' : '-manage_account'}`}>
                {currentMode === 'default' ? (
                    <>
                        <div className="wrapper-video">
                            <WrapperVideo
                                muted={true}
                                alt="ici"
                                src={{
                                    en: {
                                        desktop: {
                                            webm: require('./../../Assets/MP4/dashboard/banner_desktop.webm'),
                                            mp4: require('./../../Assets/MP4/dashboard/banner_desktop.webm'),
                                        },
                                        mobile: {
                                            webm: require('./../../Assets/MP4/dashboard/banner_desktop.webm'),
                                            mp4: require('./../../Assets/MP4/dashboard/banner_desktop.webm'),
                                        },
                                    },
                                    fr: {
                                        desktop: {
                                            webm: require('./../../Assets/MP4/dashboard/banner_desktop.webm'),
                                            mp4: require('./../../Assets/MP4/dashboard/banner_desktop.webm'),
                                        },
                                        mobile: {
                                            webm: require('./../../Assets/MP4/dashboard/banner_desktop.webm'),
                                            mp4: require('./../../Assets/MP4/dashboard/banner_desktop.webm'),
                                        },
                                    },
                                    es: {
                                        desktop: {
                                            webm: require('./../../Assets/MP4/dashboard/banner_desktop.webm'),
                                            mp4: require('./../../Assets/MP4/dashboard/banner_desktop.webm'),
                                        },
                                        mobile: {
                                            webm: require('./../../Assets/MP4/dashboard/banner_desktop.webm'),
                                            mp4: require('./../../Assets/MP4/dashboard/banner_desktop.webm'),
                                        },
                                    },
                                    it: {
                                        desktop: {
                                            webm: require('./../../Assets/MP4/dashboard/banner_desktop.webm'),
                                            mp4: require('./../../Assets/MP4/dashboard/banner_desktop.webm'),
                                        },
                                        mobile: {
                                            webm: require('./../../Assets/MP4/dashboard/banner_desktop.webm'),
                                            mp4: require('./../../Assets/MP4/dashboard/banner_desktop.webm'),
                                        },
                                    },
                                }}
                            />
                        </div>
                        <div className="wrapper-user-info-top">
                            <div className="left">
                                <WrapperSection.Title className="text-first-name text-white">
                                    {t('welcome')}, {userStore.userInfo.first_name}!
                                </WrapperSection.Title>
                            </div>

                            <div className="right">
                                <DropdownUser
                                    onClickLogout={onClickLogout}
                                    onClickManageAccount={onClickManageAccount}
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="wrapper-user-info-top -manage_account">
                        <div className="wrapper-user-info-banner">
                            <div className="left">
                                <div className="text-white deprecated-text-display-small">
                                    {t('DashboardPage.manage_account_section_title')}
                                </div>
                                <div className="text-white deprecated-text-title-medium">
                                    {t('DashboardPage.manage_account_section_description')}
                                </div>
                            </div>

                            <div className="right">
                                <LoginIcon fill={theme['white']} width={24} height={24} />
                                <div className="text-white" onClick={onClickLogout}>
                                    {t('logout')}
                                </div>
                            </div>
                        </div>

                        <div
                            className="wrapper-bottom-info"
                            onClick={() => {
                                if (currentMode === 'manage_account') setCurrentMode('default');

                                if (currentMode === 'manage_account_change_password')
                                    setCurrentMode('manage_account');
                            }}
                        >
                            <div className="wrapper-back">
                                <LeftArrowIcon fill="white" width={24} />
                                <div className="text-white">{t('back')}</div>
                            </div>

                            <div className="text-white deprecated-text-headline-medium">
                                {t('DashboardPage.manage_account_account_information_title')}
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="bottom">
                <div
                    className={`cards ${
                        currentMode === 'default' ? '-default' : '-manage_account'
                    }`}
                >
                    {currentMode !== 'default' && (
                        <CardDashboard
                            className={`card-account ${'-manage_account'}`}
                            children={
                                <div className="content-manage-account-card">
                                    <div className="deprecated-text-headline-medium">
                                        {concatCompanyName}
                                    </div>
                                    <div className="user-name deprecated-text-title-large-bold">
                                        {userStore.userInfo.first_name}{' '}
                                        {userStore.userInfo.last_name}
                                    </div>
                                    <div className="deprecated-text-body-large">
                                        {userStore.userInfo.company_role}
                                    </div>
                                    <div
                                        className={`user-mail deprecated-text-title-medium ${'-manage_account'}`}
                                    >
                                        {100 < 1350 ? (
                                            userStore.userInfo.email.length > 20 ? (
                                                <div>
                                                    <div>
                                                        {userStore.userInfo.email.substring(0, 20)}
                                                    </div>
                                                    <div>
                                                        {userStore.userInfo.email.substring(20, 40)}
                                                        {userStore.userInfo.email.length > 40
                                                            ? '...'
                                                            : ''}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>{userStore.userInfo.email}</div>
                                            )
                                        ) : (
                                            userStore.userInfo.email
                                        )}
                                    </div>

                                    <div className="separator" />

                                    <div
                                        className="delete-account deprecated-text-title-medium-bold"
                                        onClick={handleDeleteAccount}
                                    >
                                        {t('ManageAccount.delete_account')}
                                    </div>
                                </div>
                            }
                        />
                    )}
                    {currentMode === 'default'
                        ? Cards.map((card, index) => {
                              return <CardDashboard className="animated" key={index} {...card} />;
                          })
                        : null}
                </div>

                {currentMode !== 'default' && (
                    <div
                        className="wrapper-bottom-info"
                        onClick={() => {
                            if (currentMode === 'manage_account') setCurrentMode('default');

                            if (currentMode === 'manage_account_change_password')
                                setCurrentMode('manage_account');
                        }}
                    >
                        <div className="wrapper-back">
                            <LeftArrowIcon fill="black" width={24} />
                            <div className="">{t('back')}</div>
                        </div>

                        <div className="deprecated-text-headline-medium">
                            {currentMode === 'manage_account_change_password'
                                ? t('DashboardPage.manage_account_update_password_title')
                                : t('DashboardPage.manage_account_account_information_title')}
                        </div>
                    </div>
                )}

                {currentMode === 'manage_account' ? (
                    <div className="account-form">
                        <Form
                            formHeaderLine={false}
                            onSubmit={onSubmit}
                            validation={[
                                {
                                    inputName: 'first_name',
                                    required: true,
                                    minLength: 2,
                                },
                                {
                                    inputName: 'last_name',
                                    required: true,
                                    minLength: 2,
                                },
                                {
                                    inputName: 'company',
                                    required: true,
                                    minLength: 2,
                                },
                                {
                                    inputName: 'role',
                                    required: true,
                                    minLength: 2,
                                },
                                {
                                    inputName: 'company_url',
                                    required: false,
                                    minLength: 10,
                                    isUrl: true,
                                },
                            ]}
                        >
                            <Form.BasicInput
                                label={t('ManageAccount.input_first_name')}
                                placeholder={t('first_name')}
                                name="first_name"
                                defaultValue={userStore?.userInfo?.first_name}
                            />
                            <Form.BasicInput
                                label={t('ManageAccount.input_last_name')}
                                placeholder={t('last_name')}
                                name="last_name"
                                defaultValue={userStore?.userInfo?.last_name}
                            />

                            <Form.BasicInput
                                label={t('ManageAccount.input_company')}
                                placeholder={t('company')}
                                name="company"
                                defaultValue={userStore?.userInfo?.company}
                            />
                            <Form.BasicInput
                                label={t('ManageAccount.input_role')}
                                placeholder={t('role')}
                                name="role"
                                defaultValue={userStore?.userInfo?.company_role}
                            />

                            <Form.BasicInput
                                label={t('company_url')}
                                placeholder={t('www.example.com')}
                                name="company_url"
                                defaultValue={userStore.userInfo?.company_url}
                                style={{ gridColumn: 'span 2' }}
                            />

                            <Form.BasicInput
                                label={t('ManageAccount.input_email')}
                                placeholder={t('email')}
                                name="email"
                                defaultValue={userStore.userInfo?.email}
                                disabled
                            />

                            <div className="input-change-password-container">
                                <div>{t('password')}</div>

                                <div className="input-change-password-bottom cursor-pointer">
                                    <div>*******</div>
                                    <div
                                        className="right"
                                        onClick={() => {
                                            setCurrentMode('manage_account_change_password');
                                        }}
                                    >
                                        {t('change')}
                                    </div>
                                </div>
                            </div>

                            <div className="update-container">
                                <Form.Submit text={t('update')} />
                            </div>
                        </Form>
                    </div>
                ) : currentMode === 'manage_account_change_password' ? (
                    <div className="change-password-form">
                        <Form
                            onSubmit={onSubmitChangePassword}
                            formHeaderLine={false}
                            validation={[
                                {
                                    inputName: 'password',
                                    required: true,
                                    minLength: 8,
                                },
                                {
                                    inputName: 'confirm_password',
                                    required: true,
                                    minLength: 8,
                                },
                                {
                                    inputName: 'current_password',
                                    required: true,
                                },
                            ]}
                        >
                            <Form.PasswordInput
                                label={t('ManageAccount.input_current_password')}
                                name="current_password"
                                errors={
                                    currentPasswordIsError && !!passwordErrorMessage
                                        ? [
                                              {
                                                  extraInformations: t(passwordErrorMessage),
                                              },
                                          ]
                                        : []
                                }
                            />

                            <div className="separator" />

                            <Form.PasswordInput
                                label={t('ManageAccount.input_new_password')}
                                name="password"
                            />
                            <Form.PasswordInput
                                label={t('ManageAccount.input_confirm_new_password')}
                                name="confirm_password"
                            />

                            <Form.Submit text={t('ManageAccount.submit_new_password')} />
                        </Form>
                    </div>
                ) : null}
            </div>

            {isShowModalDeleteAccount ? (
                <Modal onClose={handleDeleteAccount} className="card-account-modal">
                    <div className="card-account-modal-content">
                        <div className="deprecated-text-display-small">
                            {t('ManageAccount.are_you_sur_delete_account_title')}
                        </div>

                        <div className="modal-description mt-20">
                            {t('ManageAccount.are_you_sur_delete_account_desc')}
                        </div>

                        <div className="modal-description mt-40">
                            {t('ManageAccount.are_you_sur_delete_account_sub_desc')}
                        </div>

                        <div className="spacer" />

                        <div className="row-btn">
                            <Button
                                type="white-with-border"
                                onClick={handleDeleteAccount}
                                text={t('ManageAccount.are_you_sur_delete_account_cancel')}
                            />
                            <Button
                                type="primary"
                                onClick={() => {
                                    confirmDeleteAccount();
                                    handleDeleteAccount();
                                }}
                                text={t('ManageAccount.are_you_sur_delete_account_submit')}
                            />
                        </div>
                    </div>
                </Modal>
            ) : null}
        </div>
    );
});

export default DashboardPage;
