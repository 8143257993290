import React, { FunctionComponent, useMemo, useState } from 'react';
import Button from '../../../Components/Button';
import { useTranslation } from 'react-i18next';
import WhiteCard from '../../../Components/WhiteCard';
import '../stepBaseStyle.scss';
import './index.scss';
import { DoubleArrowDownIcon } from '../../../Icons/double-arrow-down-icon';
import ProgressBar from '../Components/ProgressBar';
import { CheckmarkIcon } from '../../../Icons/checkmark-icon';
import { theme } from '../../../Style/theme';
import Checkbox from '../../../Components/Checkbox';
import './step3-mobile.scss';
import { useWindowDimensions } from '../../../Hooks/use-window-dimensions';
import { Step3Props } from '.';
import CustomTermsConditionsPage from '../../LegalText/CustomerTermsConditions';

const Step3Mobile: FunctionComponent<Step3Props> = ({
    onPrevClick,
    onNextClick,
    elementsAreFocusable,
}) => {
    const { t } = useTranslation();
    const { windowWidth } = useWindowDimensions();
    const [scrolled, setScrolled] = useState(false);
    const [scrollProgress, setScrollProgress] = useState(0);
    const [checkboxState, setCheckboxState] = useState(false);

    const handleAgreementScroll = (progress: number) => {
        setScrolled(true);
        setScrollProgress((prev) => {
            if (prev < progress) return progress;
            return prev;
        });
    };

    const overLayoutElementStyle = useMemo(() => {
        return {
            //basically just setting the elements a little bigger than screen and playing with right value so its centered correctly,
            width: `calc(${windowWidth}px + 5px)`,
            right: `calc(${windowWidth}px / 19 * -1)`,
        };
    }, [windowWidth]);

    const titleFromScrolling = useMemo((): string => {
        if (scrollProgress < 20) return t('CreateAccount.step_3_title_1');
        if (scrollProgress < 40) return t('CreateAccount.step_3_title_2');
        if (scrollProgress < 60) return t('CreateAccount.step_3_title_3');
        if (scrollProgress < 80) return t('CreateAccount.step_3_title_4');

        return t('CreateAccount.step_3_title_5');
    }, [scrollProgress, t]);

    const BREAKPOINT_FINISH_SCROLL = 98;

    return (
        <div className="Step3Mobile">
            <div className="progress-container" style={overLayoutElementStyle}>
                <ProgressBar progress={scrollProgress} />
            </div>

            <div className="top-section">
                <div className="navigation-container">
                    <Button
                        type="borderless"
                        className="prev-btn cursor-pointer"
                        text={t('previous_step')}
                        onClick={onPrevClick}
                        isFocusable={elementsAreFocusable}
                    />
                </div>
                <h3 className="deprecated-text-title-medium text-surface-grey-primary">
                    {t('CreateAccount.step_3_subtitle')}
                </h3>
                <h1 className="deprecated-text-headline-medium">{titleFromScrolling}</h1>
            </div>

            <WhiteCard onScroll={handleAgreementScroll}>
                <CustomTermsConditionsPage />

                <div className="checkmark">
                    <CheckmarkIcon
                        width={105}
                        height={105}
                        fill={theme['surface-brand-rest-primary']}
                    />
                </div>
                <div className={`fade ${scrolled ? 'fade-down' : ''}`}>
                    <div className="scroll-down">
                        {t('scroll_down')}
                        <DoubleArrowDownIcon />
                    </div>
                </div>
            </WhiteCard>

            <div
                style={overLayoutElementStyle}
                className={`bottom-section ${
                    scrollProgress >= BREAKPOINT_FINISH_SCROLL ? 'complete' : ''
                }`}
            >
                <div className="inner">
                    <Checkbox
                        label={t('CreateAccount.agree_with_terms')}
                        onChange={() => setCheckboxState((prev) => !prev)}
                        checked={checkboxState}
                    />

                    <Button
                        text={t('CreateAccount.complete_partner_account')}
                        type="primary"
                        onClick={onNextClick}
                        disabled={!checkboxState}
                        isFocusable={elementsAreFocusable}
                    />

                    <Button
                        type="borderless"
                        className="prev-btn cursor-pointer"
                        text={t('previous_step')}
                        onClick={onPrevClick}
                        isFocusable={elementsAreFocusable}
                    />
                </div>
            </div>
        </div>
    );
};

export default Step3Mobile;
