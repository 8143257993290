import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import Hero from './Sections/Hero';
import Explanation from './Sections/Explanation';
import HowItWorks from './Sections/HowItWorks';
import Benefits from './Sections/Benefits';
import SharingAndFeedback from './Sections/SharingAndFeedback';
import IntellectualProperty from './Sections/IntellectualProperty';
import CompanyLeadership from './Sections/CompanyLeadership';
import Partner from './Sections/Partner';
import FuturOfFashionSection from './Sections/FuturOfFashion';

import CONSTANTS from '../../Constants';
import { useStores } from '../../Hooks/use-stores';
import useCustomNavigation from '../../Hooks/use-custom-navigation';

import './index.scss';
import Annoucement from './Sections/Annoucement';

const HomePage = () => {
    const nav = useCustomNavigation();
    const { welcomeModalStore, userStore, tokenStore, languageStore } = useStores();
    const { t } = useTranslation();

    const loginWithToken = async (token: string) => {
        try {
            tokenStore.setAccessTokenInLocalStorage(token);
            await userStore.loginWithAccessToken();

            welcomeModalStore.setIsVisible(true);
            nav(CONSTANTS.SCREEN.DASHBOARD);
        } catch (error) {
            throw new Error('Error logging in with token');
        }
    };

    useEffect(() => {
        if (languageStore.currentLanguage === null) {
            return;
        }

        const welcomeInUrlIsTrue =
            new URLSearchParams(window.location.search).get('welcome') === 'true';
        const approvedInUrlIsTrue =
            new URLSearchParams(window.location.search).get('approved') === 'true';

        if (welcomeInUrlIsTrue) {
            nav(CONSTANTS.SCREEN.EMAIL_CONFIRMED);
            return;
        }

        if (approvedInUrlIsTrue) {
            try {
                const tokenInUrl = new URLSearchParams(window.location.search).get('token');
                if (!tokenInUrl) {
                    throw new Error('Token is not in the url');
                }

                const decodeToken: any = atob(tokenInUrl);
                if (!decodeToken) {
                    throw new Error('Token is not valid');
                }

                const token = JSON.parse(decodeToken).access_token;
                if (!token) {
                    throw new Error('Token is not valid');
                }

                loginWithToken(token);
            } catch (error) {
                console.error('Error logging in with token', error);
                toast.error(t('generic_api_error') as string);

                tokenStore.clearTokens();
                userStore.clearUserInfo();
                nav(CONSTANTS.SCREEN.HOME);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languageStore.currentLanguage]);

    return (
        <div className="HomePage">
            <Hero />
            <Explanation />
            <Annoucement />
            <HowItWorks />
            <Benefits />
            <SharingAndFeedback />
            <IntellectualProperty />
            <CompanyLeadership />
            <Partner />
            <FuturOfFashionSection />
        </div>
    );
};

export default HomePage;
