import React, { useState } from 'react';
import './member-of-team.scss';
import WrapperImage from '../../../../../Components/WrapperImage';
import { useInterval } from '../../../../../Hooks/use-interval';

interface MemberOfTeamProps {
    image: string;
    imageReverse: string;
    name: string;
    jobTitle: string;
    isBlocked: boolean;
    setIsBlocked: (isBlocked: boolean) => void;
}

const MemberOfTeam: React.FC<MemberOfTeamProps> = ({
    image,
    name,
    jobTitle,
    imageReverse,
    isBlocked,
    setIsBlocked,
}) => {
    const [isSelected, setIsSelected] = useState(false);

    const INTERVAL_TIME = 2000;
    const PERCENTAGE_RANDOM = 0.9;

    const onClick = () => {
        if (isSelected) {
            setIsBlocked(false);
            setIsSelected(false);
            return;
        }
        setIsBlocked(true);
        setIsSelected((prev) => !prev);
    };

    const randomlySelectedCard = () => {
        const random = Math.random() >= PERCENTAGE_RANDOM;
        setIsSelected(random);
    };

    useInterval(randomlySelectedCard, isBlocked ? null : INTERVAL_TIME);

    return (
        <div className="MemberOfTeam">
            <div className={`card-container ${isSelected ? '-selected' : ''}`}>
                <div className="side -front">
                    <WrapperImage uniqueSrc={image} alt="Team member" onClick={onClick} />
                </div>

                <div className="side -back">
                    <WrapperImage uniqueSrc={imageReverse} alt="Team member" onClick={onClick} />
                </div>
            </div>

            <div className="info">
                <div className="text-body-primary-font-bold name text-surface-grey-opposite">
                    {name}
                </div>
                <div className="text-body text-element-greys-secondary job-title">{jobTitle}</div>
            </div>
        </div>
    );
};

export default MemberOfTeam;
