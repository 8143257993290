import InclusionsAndImpactsHero from './Sections/Hero';
import './index.scss';
import InclusionsAndImpactsOurGoals from './Sections/OurGoals';
import InclusionsAndImpactsCoreValues from './Sections/CoreValues';
import ParallaxSection from './Sections/Parallax';
import FuturOfFashionSection from '../Home/Sections/FuturOfFashion';
import SustainabilitySection from './Sections/Sustainability';

const ImpactsAndInclusions = () => {
    return (
        <div className="ImpactsAndInclusions">
            <InclusionsAndImpactsHero />
            <ParallaxSection />
            <SustainabilitySection />
            <InclusionsAndImpactsCoreValues />
            <InclusionsAndImpactsOurGoals />
            <FuturOfFashionSection />
        </div>
    );
};

export default ImpactsAndInclusions;
